import * as moment from 'moment-timezone';
import DateUtil from '@date-io/moment';

const dateFn = new DateUtil();

export const formatDateTime = (
  datetime: Date | undefined | null,
  format = 'ddd, MMM DD, YYYY hh:mm A',
) => {
  return datetime ? dateFn.moment(datetime).format(format) : '';
};

export const getTimeZone = (ignoreCache = false): string =>
  moment.tz.guess(ignoreCache);
