import React from 'react';
import { toast } from 'react-toastify';
import LoadingSnackbar from './LoadingSnackbar';

export const generalNotification = (message) => {
  return toast(message);
};

export const startLoadingSnackbar = (message) => {
  return toast(<LoadingSnackbar message={message} loading />, {
    autoClose: false,
    closeButton: false,
    closeOnClick: false,
    draggable: false,
  });
};

export const stopLoadingSnackbar = (message, toastId) => {
  return toast.update(toastId, {
    render: <LoadingSnackbar message={message} loading={false} />,
    autoClose: 5000,
    closeButton: false,
    closeOnClick: false,
    draggable: false,
  });
};
