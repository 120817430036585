import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware, { END } from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { IS_LOCAL } from 'utils/env';
import sagaManager from './sagaManager';
import createRootReducer from './rootReducer';
import rootSaga from './rootSaga';

export default (initState) => {
  const history = createBrowserHistory();
  const reducer = createRootReducer(history);
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(
    reducer,
    initState,
    compose(applyMiddleware(sagaMiddleware, routerMiddleware(history))),
  );
  store.close = () => store.dispatch(END);
  sagaManager.startSagas([rootSaga], sagaMiddleware);
  if (IS_LOCAL && module.hot) {
    module.hot.accept('./rootReducer', () => {
      store.replaceReducer(createRootReducer(history));
      sagaManager.cancelSagas(store);
      sagaManager.startSagas([rootSaga], sagaMiddleware);
    });
  }

  return { history, store };
};
