import { take, fork, cancel } from 'redux-saga/effects';
import { IS_LOCAL } from 'utils/env';

export const CANCEL_SAGAS_HMR = 'CANCEL_SAGAS_HMR';

function createAbortableSaga(saga) {
  if (IS_LOCAL) {
    return function* main() {
      const sagaTask = yield fork(saga);

      yield take(CANCEL_SAGAS_HMR);
      yield cancel(sagaTask);
    };
  }
  return saga;
}

const SagaManager = {
  startSagas(sagas, sagaMiddleware) {
    sagas.map(createAbortableSaga).forEach((saga) => {
      const rootTask = sagaMiddleware.run(saga);
      rootTask.toPromise().catch((error) => {
        // log.appErrorCatch(error);
        if (IS_LOCAL) {
          console.error('Saga Error', error);
        }
      });
    });
  },

  cancelSagas(store) {
    store.dispatch({
      type: CANCEL_SAGAS_HMR,
    });
  },
};

export default SagaManager;
