import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
// import purple from '@material-ui/core/colors/purple';
// import green from '@material-ui/core/colors/green';

const theme = createMuiTheme({
  palette: {
    //  primary: purple,
    //  secondary: green,
  },
  props: {
    MuiTextField: {
      margin: 'normal',
    },
    MuiFormControl: {
      margin: 'normal',
    },
  },
});

export default responsiveFontSizes(theme);
