const {
  npm_package_version: NPM_PACKAGE_VERSION,
  REACT_APP_APP_ENV: APP_ENV,
  REACT_APP_FIREBASE_FUNCTIONS_HOST,
  REACT_APP_SENTRY_DSN,
} = process.env;

// env config
export const RELEASE_VERSION = NPM_PACKAGE_VERSION;
export const ENVIRONMENT = APP_ENV;
export const IS_PRODUCTION = ENVIRONMENT === 'production';
export const IS_LOCAL = ENVIRONMENT === 'local';
export const IS_DEVELOPMENT = ENVIRONMENT === 'development';
export const IS_STAGING = ENVIRONMENT === 'staging';

// firebase config
export const FIREBASE_FUNCTIONS_HOST = REACT_APP_FIREBASE_FUNCTIONS_HOST;

// sentry config
export const SENTRY_DSN = REACT_APP_SENTRY_DSN;
