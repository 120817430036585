import React from 'react';
import { Route, Switch } from 'react-router-dom';
import IncidentViewScreen from 'screens/Incident/IncidentViewScreen';
import NotFoundScreen from 'screens/NotFoundScreen';

const PublicRouter: React.FC = () => (
  <Switch>
    <Route path="/incidentView/:incidentId/:token">
      <IncidentViewScreen />
    </Route>
    <Route path="*">
      <NotFoundScreen />
    </Route>
  </Switch>
);

export default PublicRouter;
