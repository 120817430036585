import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { LocalizationProvider } from '@material-ui/pickers';
import MomentUtils from '@material-ui/pickers/adapter/moment';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import theme from 'theme';
import configureStore from 'store/configureStore';
import RootRouter from 'routes/RootRouter';
import {
  ENVIRONMENT,
  IS_LOCAL,
  RELEASE_VERSION,
  SENTRY_DSN,
} from './utils/env';

Sentry.init({
  release: RELEASE_VERSION,
  dsn: SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  debug: IS_LOCAL,
  enabled: !IS_LOCAL,
  environment: ENVIRONMENT,
});

function App() {
  const { store, history } = configureStore();
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <LocalizationProvider dateAdapter={MomentUtils}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <RootRouter />
            <ToastContainer
              position="bottom-center"
              hideProgressBar
              transition={Slide}
            />
          </ThemeProvider>
        </LocalizationProvider>
      </ConnectedRouter>
    </Provider>
  );
}

export default App;
