import React from 'react';
import { useParams } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import useAxios from 'axios-hooks';
import { getIncidentUrl } from 'services/firebase';
import LoadingCenterPage from 'components/LoadingCenterPage';
import IncidentDetails from './IncidentDetails';

const IncidentViewScreen = () => {
  const { incidentId, token } = useParams();

  const [{ data, loading, error }] = useAxios({
    url: getIncidentUrl(),
    method: 'POST',
    data: {
      incidentId,
      token,
    },
  });

  if (loading) {
    return <LoadingCenterPage />;
  }

  if (error) {
    // TODO add error message
    return (
      <Container>
        <Typography variant="h4" align="center">
          Something went wrong, no report is available
        </Typography>
      </Container>
    );
  }

  return <IncidentDetails id={incidentId} token={token} incident={data} />;
};

export default IncidentViewScreen;
