import React, { useCallback } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import { getTimeZone, formatDateTime } from 'utils/time';
import { logError } from 'utils/sentry';
import {
  startLoadingSnackbar,
  stopLoadingSnackbar,
} from 'components/Notification';
import axios from 'axios';
import { getDownloadIncidentUrl } from 'services/firebase';
import { Incident } from './types';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    paddingLeft: `${theme.spacing(0)}px`,
    paddingRight: `${theme.spacing(0)}px`,
  },
  paperHeader: {
    paddingTop: `${theme.spacing(4)}px`,
    paddingBottom: `${theme.spacing(2)}px`,
  },
  buttonDownload: {
    marginTop: `${theme.spacing(3)}px`,
    marginBottom: `${theme.spacing(1)}px`,
  },
  subtitle: {
    color: '#455a64',
    marginTop: `${theme.spacing(0)}px`,
    marginBottom: `${theme.spacing(0)}px`,
  },
  listItemText: { color: '#263238', fontWeight: 500 },
  pageTitle: {
    marginBottom: `${theme.spacing(2)}px`,
  },
  containerPaper: {
    marginTop: `${theme.spacing(4)}px`,
    paddingTop: `${theme.spacing(4)}px`,
    paddingBottom: `${theme.spacing(4)}px`,
    paddingLeft: `${theme.spacing(4)}px`,
    paddingRight: `${theme.spacing(4)}px`,
  },
  incidentImage: {
    width: 160,
    height: 160,
    borderRadius: 4,
    objectFit: 'cover',
  },
  incidentImageDiv: {
    display: 'inline-block',
    marginTop: 5,
    marginRight: 5,
    position: 'relative',
  },
}));

interface Props {
  id: string;
  token: string;
  incident: Incident;
}

const IncidentDetails = ({ id, token, incident }: Props) => {
  const classes = useStyles();
  const onHandleDownloadClick = useCallback(() => {
    const download = async () => {
      const toastId = startLoadingSnackbar('Preparing file for download...');
      try {
        const result = await axios.post(getDownloadIncidentUrl(), {
          incidentId: id,
          token,
          timeZone: getTimeZone(),
        });

        const errorMessage = result?.data?.message ?? 'Please try again';
        const downloadUrl = result?.data?.url;
        const isSuccess = result?.data?.success === true && downloadUrl;

        if (!isSuccess) {
          stopLoadingSnackbar(errorMessage, toastId);
          return;
        }

        stopLoadingSnackbar('Download will begin shortly...', toastId);

        const res = await axios.get(downloadUrl, { responseType: 'blob' });
        const url = window.URL.createObjectURL(res.data);
        const a = document.createElement('a');
        a.setAttribute('id', 'download');
        a.href = url;
        a.download = `Incident_${id}.pdf`;
        a.click();
      } catch (e) {
        logError(e);
        stopLoadingSnackbar(`Sorry, an unexpected error has occurred`, toastId);
      }
    };
    if (id && token) {
      download();
    }
  }, [id, token]);

  if (!incident) {
    return null;
  }

  const company = incident?.orgName;
  const type = incident?.type;
  const dateTime = incident?.dateTime;
  const createdAt = incident?.createdAt;
  const userName = incident?.userName;
  const client = incident?.clientName;
  const siteName = incident?.siteName;
  const description = incident?.description;
  const locationName = incident?.locationName;
  const responderReportNumber = incident?.responderReportNumber;
  const responderTimeOfArrival = incident?.responderTimeOfArrival;
  const responderBadgeNumber = incident?.responderBadgeNumber;
  const responderDepartment = incident?.responderDepartment;
  const witnesses = incident?.witnesses ? incident.witnesses.join(', ') : '-';
  const suspects = incident?.suspects ? incident.suspects.join(', ') : '-';
  const authoritiesNotified = incident?.authoritiesNotified ? 'Yes' : 'No';
  const clientNotified = incident?.clientNotified ? 'Yes' : 'No';
  const images = incident?.images;

  return (
    <Container maxWidth={false} className={classes.wrapper}>
      <Paper square className={classes.paperHeader}>
        <Container maxWidth="lg">
          <Grid container>
            <Grid item xs={12} sm={8}>
              <Typography
                variant="subtitle1"
                component="h6"
                className={classes.subtitle}
              >
                Incident Type
              </Typography>
              <Typography
                variant="h4"
                component="h1"
                className={classes.pageTitle}
              >
                {type}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography
                variant="subtitle1"
                component="h6"
                className={classes.subtitle}
              >
                {company}
              </Typography>
              <Typography variant="subtitle1" className={classes.subtitle}>
                Report by: {userName}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={onHandleDownloadClick}
                className={classes.buttonDownload}
              >
                Download PDF
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Paper>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={6}>
            <Box className={classes.containerPaper}>
              {images?.map((image) => (
                <div
                  key={String(image.id)}
                  className={classes.incidentImageDiv}
                >
                  <img
                    src={image.url}
                    alt="incident"
                    className={classes.incidentImage}
                  />
                </div>
              ))}
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Paper className={classes.containerPaper} variant="outlined">
              <Typography variant="subtitle1">General Information</Typography>
              <Grid container>
                {/* date & time */}
                <Grid item xs={12} md={6}>
                  <ListItem disableGutters>
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          variant="subtitle1"
                          className={classes.subtitle}
                        >
                          Date & Time
                        </Typography>
                      }
                      secondary={
                        <Typography className={classes.listItemText}>
                          {formatDateTime(dateTime)}
                        </Typography>
                      }
                    />
                  </ListItem>
                </Grid>
                {/* created at */}
                <Grid item xs={12} md={6}>
                  <ListItem disableGutters>
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          variant="subtitle1"
                          className={classes.subtitle}
                        >
                          Created at
                        </Typography>
                      }
                      secondary={
                        <Typography className={classes.listItemText}>
                          {formatDateTime(createdAt)}
                        </Typography>
                      }
                    />
                  </ListItem>
                </Grid>
                {/* client name */}
                <Grid item xs={12} md={6}>
                  <ListItem disableGutters>
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          variant="subtitle1"
                          className={classes.subtitle}
                        >
                          Client
                        </Typography>
                      }
                      secondary={
                        <Typography className={classes.listItemText}>
                          {client}
                        </Typography>
                      }
                    />
                  </ListItem>
                </Grid>
                {/* site name */}
                <Grid item xs={12} md={6}>
                  <ListItem disableGutters>
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          variant="subtitle1"
                          className={classes.subtitle}
                        >
                          Site
                        </Typography>
                      }
                      secondary={
                        <Typography className={classes.listItemText}>
                          {siteName}
                        </Typography>
                      }
                    />
                  </ListItem>
                </Grid>
                {/* location name */}
                <Grid item xs={12} md={6}>
                  <ListItem disableGutters>
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          variant="subtitle1"
                          className={classes.subtitle}
                        >
                          Location Name
                        </Typography>
                      }
                      secondary={
                        <Typography className={classes.listItemText}>
                          {locationName}
                        </Typography>
                      }
                    />
                  </ListItem>
                </Grid>
                {/* client notified */}
                <Grid item xs={12} md={6}>
                  <ListItem disableGutters>
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          variant="subtitle1"
                          className={classes.subtitle}
                        >
                          Client Notified
                        </Typography>
                      }
                      secondary={
                        <Typography className={classes.listItemText}>
                          {clientNotified}
                        </Typography>
                      }
                    />
                  </ListItem>
                </Grid>
                {/* divider */}
                <Grid item xs={12} md={12}>
                  <Divider />
                </Grid>
                {/* description */}
                <Grid item xs={12} md={12}>
                  <ListItem disableGutters>
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          variant="subtitle1"
                          className={classes.subtitle}
                        >
                          Description
                        </Typography>
                      }
                      secondary={
                        <Typography className={classes.listItemText}>
                          {description}
                        </Typography>
                      }
                    />
                  </ListItem>
                </Grid>
              </Grid>
            </Paper>
            <Paper className={classes.containerPaper} variant="outlined">
              <Typography variant="subtitle1">
                Additional Information
              </Typography>
              <Grid container>
                {/* authorities notified */}
                <Grid item xs={12} md={6}>
                  <ListItem disableGutters>
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          variant="subtitle1"
                          className={classes.subtitle}
                        >
                          Authorities Notified
                        </Typography>
                      }
                      secondary={
                        <Typography className={classes.listItemText}>
                          {authoritiesNotified}
                        </Typography>
                      }
                    />
                  </ListItem>
                </Grid>
                {/* responder report number notified */}
                <Grid item xs={12} md={6}>
                  <ListItem disableGutters>
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          variant="subtitle1"
                          className={classes.subtitle}
                        >
                          Responder Report #
                        </Typography>
                      }
                      secondary={
                        <Typography className={classes.listItemText}>
                          {responderReportNumber}
                        </Typography>
                      }
                    />
                  </ListItem>
                </Grid>
                {/* responder arrival time */}
                <Grid item xs={12} md={6}>
                  <ListItem disableGutters>
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          variant="subtitle1"
                          className={classes.subtitle}
                        >
                          Responder Arrival Time
                        </Typography>
                      }
                      secondary={
                        <Typography className={classes.listItemText}>
                          {formatDateTime(responderTimeOfArrival)}
                        </Typography>
                      }
                    />
                  </ListItem>
                </Grid>
                {/* responder badge number */}
                <Grid item xs={12} md={6}>
                  <ListItem disableGutters>
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          variant="subtitle1"
                          className={classes.subtitle}
                        >
                          Responder Badge #
                        </Typography>
                      }
                      secondary={
                        <Typography className={classes.listItemText}>
                          {responderBadgeNumber}
                        </Typography>
                      }
                    />
                  </ListItem>
                </Grid>
                {/* responder department */}
                <Grid item xs={12} md={6}>
                  <ListItem disableGutters>
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          variant="subtitle1"
                          className={classes.subtitle}
                        >
                          Responder Department
                        </Typography>
                      }
                      secondary={
                        <Typography className={classes.listItemText}>
                          {responderDepartment}
                        </Typography>
                      }
                    />
                  </ListItem>
                </Grid>
                {/* divider */}
                <Grid item xs={12} md={12}>
                  <Divider />
                </Grid>
                {/* suspects */}
                <Grid item xs={12} md={6}>
                  <ListItem disableGutters>
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          variant="subtitle1"
                          className={classes.subtitle}
                        >
                          Suspects
                        </Typography>
                      }
                      secondary={
                        <Typography className={classes.listItemText}>
                          {suspects}
                        </Typography>
                      }
                    />
                  </ListItem>
                </Grid>
                {/* witnesses */}
                <Grid item xs={12} md={6}>
                  <ListItem disableGutters>
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          variant="subtitle1"
                          className={classes.subtitle}
                        >
                          Witnesses
                        </Typography>
                      }
                      secondary={
                        <Typography className={classes.listItemText}>
                          {witnesses}
                        </Typography>
                      }
                    />
                  </ListItem>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
};

export default IncidentDetails;
