import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import OkIcon from '@material-ui/icons/Done';
import ErrorIcon from '@material-ui/icons/Error';
import CircleLoading from '../CircleLoading';

interface Props {
  ok?: boolean;
  loading: boolean;
  message: string;
}
export default ({ ok = true, message, loading }: Props) => {
  return (
    <Grid container spacing={2}>
      <Grid item>
        {loading ? <CircleLoading /> : ok ? <OkIcon /> : <ErrorIcon />}
      </Grid>
      <Grid item>
        <Typography>{message}</Typography>
      </Grid>
    </Grid>
  );
};
