import * as Sentry from '@sentry/react';
import { IS_LOCAL } from './env';

export const logError = (error: any) => {
  if (!error) {
    return;
  }
  if (IS_LOCAL) {
    console.error(error);
  }
  Sentry.captureException(error);
};
