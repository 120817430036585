import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

const NotFoundScreen = () => {
  return (
    <Container>
      <Typography variant="h4" align="center">
        Something went wrong, no report is available
      </Typography>
    </Container>
  );
};

export default NotFoundScreen;
